import React from "react";
import "./TermsAndConditions.css";

const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <h2>General Terms</h2>
      <p className="terms-paragraph">
        <strong>melbet.com</strong> is operated by Alenesro Ltd (registration number HE 399995), with a registered office at Aristofanous, 219, Mauros Court 140, Flat/Office 202, Strovolos, 2038, Nicosia, Cyprus, acting as a Billing Agent. The betting company provides services for placing bets on sports and other events that occur internationally. In betting shops, wagers are only accepted and winnings paid out upon presentation of valid identification.
      </p>

      {/* <h3>Eligibility and Account Rules</h3> */}
      <p className="terms-paragraph">
        Bets are accepted from individuals aged 18 years and above, who agree to comply with the Rules provided by the betting company. In case of any breach of these rules, the company reserves the right to refuse payment of winnings, return any stakes already placed, or cancel bets.
      </p>
      <p className="terms-paragraph">
        Clients are allowed only one account (registration is allowed per person, family, household, computer, IP address, credit/debit card, e-wallet, or electronic payment method). Persons connected with a registered customer cannot register. Otherwise, the Security Department has the right to suspend such accounts for up to two months, and winning bets may be recalculated.
      </p>

      {/* <h3>Betting Rules</h3> */}
      <p className="terms-paragraph">Conditions of bet acceptance, including odds, handicaps, totals, and maximum limits, may change after any bet, but bets already placed will not be affected. Clients are encouraged to review any changes in the Sports line before placing their bets.

      </p>
<p className="terms-paragraph">The betting company reserves the right to refuse a bet without explanation.</p> 
<p className="terms-paragraph">In case of errors during bet acceptance (e.g., typos in the betting list, odds inconsistencies) or if there is evidence of unfair play or violation of Rules, the company reserves the right to declare such bets void. In such cases, payouts are made with odds equal to "1".</p>    
<p className="terms-paragraph">If there are suspicions of unsportsmanlike conduct, the company reserves the right to freeze bets on the event until an international body issues a final ruling. If unsportsmanlike conduct is confirmed, the bet will be declared invalid, and the payout will be made with odds equal to "1". The company is not obligated to provide evidence or conclusions to the customer.</p> 
<p className="terms-paragraph">In cases of incorrect odds, such bets will be recalculated based on the correct market odds at the time of the event's conclusion.</p>
<p className="terms-paragraph">If bets are settled incorrectly due to an error, they will be recalculated. However, any bets placed between the erroneous settlement and recalculation will be deemed valid. If the bettor's account becomes negative as a result of recalculation, no further bets can be placed until a sufficient deposit is made.</p>
<p className="terms-paragraph">Bets placed on events that have already concluded will be settled at odds equal to "1".</p>
<p className="terms-paragraph">The "Simultaneous Finish" rule applies to events with multiple winners, such as tournaments. If two winners are declared, the stake will be divided by two for calculating the bet. If more than two winners are declared, the bet will be settled with odds equal to "1". This rule does not apply to "To be higher" markets.</p>
<p className="terms-paragraph">A client is responsible for keeping their password and gaming account number private. Registered bets are considered valid and can only be canceled in accordance with these Rules.</p>
<p className="terms-paragraph">An Internet connection failure while confirming a bet does not constitute grounds for cancellation of the bet.
</p>
<p className="terms-paragraph">Placing a bet confirms a client's agreement to these Rules and acceptance thereof.</p>
<p>Event results declared by the betting company are the sole basis for calculating bets and payouts. Claims about event results, date, or time will be considered along with official documents from relevant sports federations.
</p>
<p className="terms-paragraph">Once returns are received, bettors must verify the winnings. If they disagree, they must notify the bookmaker within 10 days, providing their account number, event details, and reasons for disagreement. Claims for Cyber-Live games must be submitted within 72 hours of the game's conclusion.</p>
<p className="terms-paragraph">
If a bettor attempts to commit fraud, such as registering multiple accounts, using automated betting software, or misusing loyalty schemes, the bookmaker has the right to take corrective action, including canceling bets, closing accounts, or filing claims with law enforcement.
</p>
<p className="terms-paragraph">
In exceptional cases, bettors may request a change of account currency by emailing us with the subject "CHANGE OF CURRENCY" and including a photo of their passport's main page. A fee of 10% of the converted amount will be charged for this transaction.
</p>
<p className="terms-list">
  The bookmaker reserves the right to close an account immediately and void any bets if it is established that:
</p>
<ul className="terms-list">
  <li className="terms-list-item">The bettor had prior knowledge of the event outcome.</li>
  <li className="terms-list-item">The bettor influenced the result of the event (e.g., as an athlete, coach, or referee).</li>
  <li className="terms-list-item">Bets were placed as part of a syndicate to exceed betting limits.</li>
  <li className="terms-list-item">The bettor has multiple accounts.</li>
  <li className="terms-list-item">The bettor used special software for automated betting.</li>
  <li className="terms-list-item">Unfair methods were used to obtain information or bypass restrictions.</li>
</ul>
<p className="terms-paragraph">
    Balances on customer accounts will be refunded after investigations, excluding any unfair profits. The company may not refund service fees incurred during deposits or withdrawals.
  </p>

  <p className="terms-paragraph">
    If the Security Service has concerns about a bettor's identity or information, they can request supporting documents and cancel payments until details are verified. Verification may take up to 72 hours, and if the information is proven false, the bookmaker may suspend all transactions indefinitely.
  </p>

  <p className="terms-paragraph">
    The company is not responsible for translation errors of team names, player surnames, or locations of competitions. Such auxiliary information does not warrant a refund.
  </p>

  <p className="terms-paragraph">
    The company reserves the right to add or update these rules. New rules become effective immediately upon posting.
  </p>

  <p className="terms-paragraph">
    The company is not liable for losses resulting from using the website, inability to access it, or delays in data transmission.
  </p>

  <p className="terms-paragraph">
    Users are responsible for their account security and all activities performed on their accounts.
  </p>

  <p className="terms-paragraph">
    These Terms and Conditions are governed by the laws of Cyprus.
  </p>

  <p className="terms-paragraph">
    The right to access and use the Website may be illegal in some jurisdictions, including the USA, UK, Cyprus, Netherlands, and Iran. Users must determine if their use of the Website is legal under their local laws.
  </p>

  <p className="terms-paragraph">
    The Betting Company is not liable for any false information provided by customers under these Terms.
  </p>

  <p className="terms-paragraph">
    Bets are accepted based on the current Sports line, which lists events and corresponding winning odds. Minimum stakes are set at $0.20 or equivalent, and maximum stakes vary based on the sport or event. The maximum winning amount for a single bet is $60,000 or equivalent.
  </p>

  <p className="terms-paragraph">
    The company may limit or change maximum stakes or odds for special events or specific clients without prior notice.
  </p>

  <p className="terms-paragraph">
    A bet is confirmed once registered in the system and listed in the "Bet History." If there is a delay, the bet is still considered accepted once it appears in the "Bet History."
  </p>

  <p className="terms-paragraph">
    Bets must be placed before an event starts, except for Live-bets placed during the match. Bets placed after the start of an event will be canceled unless they are Live-bets.
  </p>

  <p className="terms-paragraph">
    Incorrectly calculated winnings will be recalculated. Bets may also be canceled if a client provides false information or if placed after the event outcome was known.
  </p>

  <p className="terms-paragraph">
    Live bets cannot be changed or canceled. Clients are encouraged to use independent sources of information.
  </p>

  <p className="terms-paragraph">
    Regional championship bets are settled within 10 days of official results being posted. If a team does not participate, bets will be refunded.
  </p>

  <p className="terms-paragraph">
    Once a bet is registered, it cannot be altered or revoked.
  </p>

  <p className="terms-paragraph">
    Applicants can apply for only one agent position, with three additional sub-agents possible after three months of employment. Violations may lead to suspension of applications. Selling a method account will lead to lifetime suspension.
  </p>

  <p className="terms-paragraph">
    An appeal fee of $2000 is required to reinstate an agent, which will be credited to the agent's balance after 45 days of compliance.
  </p>

  <p className="terms-paragraph">
    Agents who violate terms again after an appeal will not be given another chance.
  </p>

  <p className="terms-paragraph">
    Wallet agents must log in at specified times, and accounts may be reassigned if missed. Agents must adhere to limits, and Melbet is not liable for any loss from the wallet.
  </p>

  <p className="terms-paragraph">
    Agents must complete deposit and withdrawal requests within 10 minutes, and Melbet may terminate agents at any time. Compliance with all applicable laws is required.
  </p>

  <p className="terms-paragraph">
    Daily commissions will be credited, but Melbet will not be responsible if an agent is cheated.
  </p>

      <footer>
        <p>Copyright © 2019 - 2024 «Melbet». All rights reserved.</p>
      </footer>
    </div>
  );
};

export default TermsAndConditions;
