import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <div className="privacy-container">
            <div className="hero">
                <h1>Privacy Policy</h1>
                <p>Protecting your data and privacy is important to us.</p>
            </div>
            <div className="section">
                <h2>Introduction</h2>
                <p>
                    This Privacy Policy outlines how MelBet collects, uses, and manages your personal information to support your account management at MelBet.
                </p>
                <p>
                    By providing your information via the MelBet website ("Website") or in other forms detailed in this Privacy Policy, you consent to the use of your personal information as described here. If you disagree with these terms, please refrain from using the Website or sharing your information in other ways.
                </p>
            </div>

            <div className="section">
                <h2>Collection and Use of Information</h2>
                <p>We may collect and use various types of information and data, including:</p>
                <ul>
                    <li>Information you provide to us by completing forms on the Website, or any other data you submit via the Website or email;</li>
                    <li>Communications made through the Website, email, phone, or any other form of correspondence;</li>
                    <li>Details of payments you make via the Website, phone, or other means;</li>
                    <li>Information related to your visits to the Website, including traffic data, location data, login details, and more.</li>
                </ul>
                <p>
                    Your personal information may be used along with other data for the following purposes:
                </p>
                <ul>
                    <li>To process your bets, bank card payments, and online transactions;</li>
                    <li>To create and manage your account;</li>
                    <li>To comply with legal obligations;</li>
                    <li>To perform data analysis;</li>
                    <li>To provide you with information on promotional offers, products, and services to which you consent;</li>
                    <li>To monitor your activities to prevent fraud, illegal betting, and money laundering.</li>
                </ul>
                <p>You can opt-out of unsolicited communications using the provided functionality.</p>
            </div>

            <div className="section">
                <h2>Disclosure of Information</h2>
                <p>
                    We may share information, including personal details and betting history, with regulatory bodies or law enforcement agencies, such as the police, to investigate cases of fraud and money laundering.
                </p>
            </div>

            <div className="section">
                <h2>Security</h2>
                <p>
                    We take reasonable measures, as required by law, to ensure that your personal information is recorded accurately and stored securely. All personal information is destroyed when no longer required or as mandated by law.
                </p>
                <p>
                    We cannot guarantee the security of information transmitted over the Internet. Any data shared with us online is at your own risk. However, once we receive your information, we take reasonable steps to protect it against misuse, loss, or unauthorized access.
                </p>
            </div>

            <div className="section">
                <h2>Changes to Our Privacy Policy</h2>
                <p>
                    Any future changes to this Privacy Policy will be published on this page and will take effect as soon as the revised policy is posted.
                </p>
            </div>

            <div className="footer">
                <p>&copy; 2024 MelBet. All rights reserved.</p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
    