import React from 'react';
import './Homepage.css'; 
import { Link } from 'react-router-dom';

const Homepage = () => {
    return (
        <>
            <div className="container">
                {/* Hero Section with Animations */}
                <div className="hero">
                    <h1>Play on Melbet</h1>
                    <p>Your Pathway to the Best Sports Betting Online.</p>
                    <div className="cta-buttons">
                        <a href="https://melbet.com/en/registration" className="primary-cta">Sign Up Now</a>
                        <a href="https://melbet.com/" className="secondary-cta">Play on Website</a>
                    </div>
                </div>

                {/* Our Facilities Section with Slider */}
                <div className="facilities-slider">
                    <div className="slider-container">
                        <div className="slide">
                            <div className="emoji">🏆</div>
                            <div className="text"><strong>Reliable bookmaker</strong><br />Since 2012</div>
                        </div>
                        <div className="slide">
                            <div className="emoji">🌍</div>
                            <div className="text"><strong>50+ countries</strong><br /> Globally Available</div>
                        </div>
                        <div className="slide">
                            <div className="emoji">👥</div>
                            <div className="text"><strong>500,000+ players</strong><br />Global user base</div>
                        </div>
                        <div className="slide">
                            <div className="emoji">💰</div>
                            <div className="text"><strong>Best terms</strong><br />Competitive betting conditions</div>
                        </div>
                        <div className="slide">
                            <div className="emoji">🔒</div>
                            <div className="text"><strong>Stable payouts</strong><br />Timely payments ,Guaranteed</div>
                        </div>
                    </div>
                </div>

                {/* About Melbet Section */}
                <div className="section">
                    <h2>About Melbet</h2>
                    <p>Melbet has been a trusted name in online sports betting since 2012. We operate under Pelican Entertainment B.V. with a Curacao license (No. 8048/JAZ2020-060). We offer secure transactions, top-notch customer support, and a wide variety of betting options.</p>
                    <ul>
                        <li>Secure transactions advanced encryption.</li>
                        <li>24/7 customer support</li>
                        <li>A trusted platform with over a decade of industry experience.</li>
                    </ul>
                </div>

                {/* Betting Options Section */}
                <div id="betting-options" className="section betting-options">
                    <h2>Betting Options</h2>
                    <ul>
                        <li><strong>Single Bet:</strong> A straightforward bet on a single outcome.</li>
                        <li><strong>System Bet:</strong> A combination of multiple accumulators.</li>
                        <li><strong>Accumulator Bet:</strong> Bet on multiple unrelated outcomes.</li>
                        <li><strong>Asian Handicap:</strong> Offers balanced winning chances.</li>
                    </ul>
                </div>

                {/* Security and Licensing Section */}
                <div className="section">
                    <h2>Security and Licensing</h2>
                    <p>Your safety is our priority. Melbet uses advanced SSL and RSA encryption to secure all transactions. We enforce mandatory KYC verification to protect your account and ensure secure deposits and withdrawals.</p>
                    <p>Licensed and regulated by the Government of Curacao (License No. 8048/JAZ2020-060), we comply with international standards for online betting.</p>
                </div>
            </div>

            {/* Footer */}
            <div className="footer">
                <p>&copy; 2024 Melbet. All rights reserved.</p>
                <Link to="/privacy-policy">Privacy Policy</Link> | <Link to="/terms-conditions">Terms & Conditions</Link>| <a href="mailto:agents@melbetretail.com">Contact Us</a>
            </div>
        </>
    );
};

export default Homepage;
